import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Pages/general"

const DisclaimerPage = () => (
  <Layout visibleSideBar={true}>
    <SEO
      title="Disclaimer"
      description="Nessa página fazemos os avisos legais sobre nossas atividades, alguma dúvida nos contate."
    />
    <S.GeneralWrapper>
      <S.GeneralTitle align="left" font>
        Disclaimer
      </S.GeneralTitle>
      <S.GeneralText>
        O site{" "}
        <S.GeneralLink
          to="/"
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          Você Empreendedor Digital
        </S.GeneralLink>{" "}
        é um participante do programa de afiliados na plataforma Hotmart. Sendo
        assim, ganhamos comissões por nossas indicações.
      </S.GeneralText>
      <S.GeneralText>
        Isso significa que somos financeiramente compensados ​​por indicar
        produtos nesses sites e quando você realiza a compra por um de nossos
        links está diretamente nos ajudando a manter o site e todo o conteúdo
        que produzimos.
      </S.GeneralText>
      <S.GeneralText>
        Lembrando, buscamos sempre o melhor para você usuário e jamais
        pensaremos apenas em dinheiro, afinal o intuito maior do{" "}
        <S.GeneralLink
          to="/"
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          Você Empreendedor Digital
        </S.GeneralLink>{" "}
        é que vocês possam aprender e realizar o seus sonhos. Logo, todas nossas
        indicações passam por um longo e criterioso processo de validação e
        aprovação para que possa sempre garantir a qualidade e o melhor para
        vocês. Ressalto que 90% do que é indicado é algo que nossa equipe faz
        uso, para que possamos ter ainda mais garantia do que ofertar.
      </S.GeneralText>
    </S.GeneralWrapper>
  </Layout>
)

export default DisclaimerPage
